<template>
  <div class="h-screen flex w-full bg-primary vx-row no-gutter items-center justify-center">
    <div class="vx-col sm:w-1/2 md:w-1/2 lg:w-3/4 xl:w-2/5 sm:m-0 m-4">
      <vx-card title="Nova franquia">
        <div slot="no-body">
          <form-wizard
            color="rgba(var(--vs-primary), 1)"
            errorColor="rgba(var(--vs-danger), 1)"
            :title="null"
            :subtitle="null"
            finishButtonText="Finalizar"
          >
            <tab-content
              title="Dados da empresa"
              class="mb-5"
              icon="feather icon-home"
              :before-change="validateEmpresa"
            >
              <!-- tab 1 content -->
              <form data-vv-scope="step-1">
                <div class="vx-row">
                  <!-- Nome fantasia -->
                  <div class="vx-col md:w-1/2 w-full mt-5">
                    <vs-input
                      class="inputx w-full"
                      label-placeholder="Nome fantasia"
                      v-model="empresa.nome_fantasia"
                      v-validate="'required|alpha'"
                      name="nome_fantasia"
                    />
                    <span
                      class="text-danger text-sm"
                      v-show="errosForm.razao_social"
                    >{{ errosForm.razao_social }}</span>
                  </div>
                  <!-- Razão social -->
                  <div class="vx-col md:w-1/2 w-full mt-5">
                    <vs-input
                      class="inputx w-full mt-6"
                      label-placeholder="Razão social"
                      v-model="empresa.razao_social"
                      v-validate="'required'"
                      name="razao_social"
                    />
                    <span
                      class="text-danger text-sm"
                      v-if="errosForm.razao_social"
                    >{{ errosForm.razao_social }}</span>
                  </div>
                  <!-- CNPJ -->
                  <div class="vx-col md:w-1/3 w-full mt-5">
                    <vs-input
                      class="inputx w-full mt-6"
                      label-placeholder="CNPJ"
                      v-model="empresa.cnpj"
                      v-validate="'required|numeric|min:14|max:14'"
                      name="cnpj"
                      v-mask="'##.###.###/####-##'"
                    />
                    <!-- <span class="text-danger text-sm" v-if="errors.has('cnpj')">
                      {{
                      $validators.cnpj
                      }}
                    </span>-->
                    <span class="text-danger"></span>
                  </div>
                  <!-- Insc. Estadual -->
                  <div class="vx-col md:w-1/3 w-full mt-5">
                    <vs-input
                      class="inputx w-full mt-6"
                      label-placeholder="Inscrição estadual"
                      v-model="empresa.inscricao_estadual"
                      v-validate="'required|numeric'"
                      name="inscricao_estadual"
                      v-mask="'###########'"
                    />
                  </div>
                  <!-- Insc. Municipal -->
                  <div class="vx-col md:w-1/3 w-full mt-5">
                    <vs-input
                      class="inputx w-full mt-6"
                      label-placeholder="Instrução municipal"
                      v-model="empresa.inscricao_municipal"
                      v-validate="'required|numeric'"
                      v-mask="'###########'"
                      name="inscricao_municipal"
                    />
                  </div>
                  <!-- CEP -->
                  <div class="vx-col md:w-1/4 w-full mt-5">
                    <vs-input
                      class="inputx w-full mt-6"
                      label-placeholder="CEP"
                      v-model="empresa.cep"
                      v-validate="'required|numeric|min:8|max:8'"
                      name="cep"
                      v-mask="'#####-###'"
                    />
                  </div>
                  <!-- Endereço -->
                  <div class="vx-col md:w-3/4 w-full mt-5">
                    <vs-input
                      class="inputx w-full mt-6"
                      label-placeholder="Endereço"
                      v-model="empresa.endereco"
                      v-validate="'required'"
                      name="endereco"
                    />
                    <span
                      class="text-danger text-sm"
                      v-if="errors.has('endereco')"
                    >{{ $validators.empty }}</span>
                  </div>
                  <!-- Telefone -->
                  <div class="vx-col md:w-1/2 w-full mt-5">
                    <vs-input
                      class="inputx w-full mt-6"
                      label-placeholder="Telefone"
                      v-model="empresa.telefone"
                      v-validate="'required|numeric|min:8|max:11'"
                      name="telefone"
                      v-mask="'(##)#########'"
                    />
                  </div>
                  <!-- Website -->
                  <div class="vx-col md:w-1/2 w-full mt-5">
                    <vs-input
                      class="inputx w-full mt-6"
                      label-placeholder="Website"
                      v-model="empresa.website"
                    />
                  </div>
                  <!-- Data de fundacao -->
                  <div class="vx-col md:w-1/2 w-full mt-5">
                    <label class="mt-4">Data de fundação</label>
                    <datepicker
                      :format="format"
                      placeholder="Selecinar data"
                      v-model="empresa.data_fundacao"
                    ></datepicker>
                  </div>
                </div>
              </form>
            </tab-content>

            <tab-content
              title="Seus dados"
              class="mb-5"
              icon="feather icon-user"
              :before-change="validateColaborador"
            >
              <!-- tab 2 content -->
              <div class="w-full">
                <div class="vx-row">
                  <div class="vx-col w-full">
                    <vs-input
                      label-placeholder="Email"
                      v-model="colaborador.email"
                      class="w-full"
                      name="email"
                    />
                  </div>
                  <div class="vx-col md:w-1/2 w-full mt-5">
                    <vs-input
                      label-placeholder="Nome completo"
                      v-model="colaborador.nome"
                      class="w-full"
                      name="nome"
                    />
                    <span
                      class="text-danger text-sm"
                      v-show="errors.has('nome')"
                    >{{ errors.first("nome") }}</span>
                  </div>
                  <div class="vx-col md:w-1/2 w-full mt-5">
                    <vs-input
                      label-placeholder="CPF"
                      v-model="colaborador.cpf"
                      class="w-full"
                      name="cpf"
                    />
                    <span class="text-danger"></span>
                  </div>
                  <div class="vx-col md:w-1/2 w-full mt-5">
                    <vs-input
                      label-placeholder="RG"
                      v-model="colaborador.rg"
                      class="w-full"
                      name="rg"
                    />
                    <span class="text-danger"></span>
                  </div>
                  <div class="vx-col md:w-1/2 w-full mt-5">
                    <vs-input
                      label-placeholder="CNH (opcional)"
                      v-model="colaborador.cnh"
                      class="w-full"
                      name="cnh"
                    />
                    <span class="text-danger"></span>
                  </div>
                </div>

                <div class="vx-row">
                  <div class="vx-col md:w-1/2 w-full mt-5">
                    <vs-input
                      label-placeholder="Celular / Telefone"
                      v-model="colaborador.telefone"
                      class="w-full"
                      name="telefone"
                    />
                    <span class="text-danger"></span>
                  </div>
                  <div class="vx-col md:w-1/2 w-full mt-2">
                    <label class="text-sm">Data de nascimento</label>
                    <datepicker
                      :format="format"
                      placeholder="Selecinar data"
                      v-model="empresa.data_nascimento"
                      label-placeholder="Data nascimento"
                    ></datepicker>

                    <span class="text-danger"></span>
                  </div>
                  <div class="vx-col w-full mt-5">
                    <vs-input
                      label-placeholder="Endereco"
                      v-model="colaborador.endereco"
                      class="w-full"
                      name="endereco"
                    />
                    <span class="text-danger"></span>
                  </div>
                </div>

                <div class="vx-row">
                  <div class="vx-col w-1/2 mt-5">
                    <vs-input
                      label-placeholder="Senha"
                      v-model="colaborador.password"
                      type="password"
                      class="w-full"
                      name="password"
                    />
                    <span class="text-danger"></span>
                  </div>
                  <div class="vx-col w-1/2 mt-5">
                    <vs-input
                      label-placeholder="Repita a senha"
                      type="password"
                      v-model="colaborador.passwordConfirm"
                      class="w-full"
                      name="password_confirm"
                    />
                    <span class="text-danger"></span>
                  </div>
                </div>

                <!--div class="vx-row">
                  <div class="vx-col w-full mt-5">
                    <vs-input
                      label-placeholder="Nome"
                      v-validate="'required'"
                      name="nome"
                      v-model="colaborador.nome"
                      class="inputx w-full"
                    />
                    <span class="text-danger text-sm" v-if="errors.has('nome')">
                      {{ $validators.empty }}
                    </span>
                  </div>
                  <div class="vx-col w-full mt-5">
                    <vs-input
                      label-placeholder="Email"
                      type="email"
                      v-validate="'required|email'"
                      name="email"
                      v-model="colaborador.email"
                      class="mt-5 w-full"
                    />
                    <span
                      class="text-danger text-sm"
                      v-if="errors.has('email')"
                    >
                      {{ $validators.email }}
                    </span>
                  </div>
                </div-->
              </div>
            </tab-content>
          </form-wizard>
        </div>
      </vx-card>
    </div>
  </div>
</template>

<script>
import { FormWizard, TabContent } from "vue-form-wizard";
import Datepicker from "vuejs-datepicker";
import moment from "moment";
import "vue-form-wizard/dist/vue-form-wizard.min.css";

export default {
  data() {
    return {
      empresa: {},
      colaborador: {},
      format: "d MMMM yyyy",
      error: false,
      erros: {},
      errosForm: {}
    };
  },
  methods: {
    empty(campo) {
      return `O campo ${campo} não pode ficar em branco.`;
    },
    incorrect(campo) {
      return `Digite um(a) ${campo} valido(a)`;
    },
    validateEmpresa() {
      return new Promise((resolve, reject) => {
        this.error = false;
        if (this.empresa.nome_fantasia == undefined) {
          this.$vs.notify(
            this.$notify.Custom(
              "Ops...",
              this.empty("nome fantasia"),
              "warning"
            )
          );
          this.error = true;
        }
        if (this.empresa.razao_social == undefined) {
          this.$vs.notify(
            this.$notify.Custom("Ops...", this.empty("razao social"), "warning")
          );
          this.error = true;
        }
        if (this.empresa.cnpj == undefined) {
          this.$vs.notify(
            this.$notify.Custom("Ops...", this.empty("CNPJ"), "warning")
          );
          this.error = true;
        } else if (this.empresa.cnpj.length != 18) {
          // CNPJ valido tem 18 caracteres
          this.$vs.notify(
            this.$notify.Custom("Ops...", this.incorrect("CNPJ"), "warning")
          );
          this.error = true;
        }
        if (this.error) {
          reject("Corrija os dados invalidos");
        } else {
          resolve(true);
        }
      });
    },
    validateColaborador() {
      return new Promise((resolve, reject) => {
        this.$validator.validateAll("step-2").then(() => {
          let error = false;
          if (this.colaborador.nome == undefined) {
            this.$vs.notify(
              this.$notify.Custom("Ops...", this.empty("nome"), "warning")
            );
            error = true;
          }
          if (this.colaborador.email == undefined) {
            this.$vs.notify(
              this.$notify.Custom("Ops...", this.empty("email"), "warning")
            );
            error = true;
          }
          if (this.colaborador.password == undefined) {
            this.$vs.notify(
              this.$notify.Custom("Ops...", this.empty("senha"), "warning")
            );
            error = true;
          } else if (this.colaborador.password.length < 6) {
            this.$vs.notify(
              this.$notify.Custom(
                "Ops...",
                `A senha deve ter mais que 6 caracteres`,
                "warning"
              )
            );
            error = true;
          } else if (
            this.colaborador.password != this.colaborador.passwordConfirm
          ) {
            this.$vs.notify(
              this.$notify.Custom("Ops...", `As senha nao coincidem`, "warning")
            );
            error = true;
          }

          if (!error) {
            this.finalizarCadastro();
            resolve(true);
          } else {
            reject("Corrija os dados invalidos");
          }
        });
      });
    },
    async finalizarCadastro() {
      let error = false;
      delete this.colaborador.passwordConfirm;
      this.$vs.loading();
      try {
        const empresa = await this.$http.post("empresa", this.empresa);
        let logData = {
          id_colaborador: localStorage.getItem("id"),
          funcao: "cadastrar",
          setor: "comercial",
          ip: window.localStorage.getItem("ip"),
          texto: "Cadastro da empresa N°" + empresa.id
        };
        await this.$logger(logData);
        this.colaborador.id_empresa = empresa.id;
        await this.$http.post("colaborador", this.colaborador);
      } catch (err) {
        error = true;
        this.$vs.notify(this.$httpErrors(err));
      } finally {
        this.$vs.loading.close();
      }
      if (!error) {
        this.$vs.notify(
          this.$notify.Custom(
            "Sucesso",
            "O cadastro foi finalizado e voce sera redirecionado para a tela de login",
            `success`
          )
        );
        setTimeout(() => {
          window.location.href = window.location.href.replace(
            "/pages/inicio",
            ""
          );
        }, 3000);
      }
    }
  },
  components: {
    FormWizard,
    TabContent,
    moment,
    Datepicker
  }
};
</script>

<style>
#div-with-loading {
  width: 100px;
  height: 100px;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  box-shadow: 0px 3px 10px 0px rgba(0, 0, 0, 0.1);
}
</style>
